import logo from './LogoHoriz-SM.png'
import hamburger from './hamburger.svg'
import './header.css'

function Header() {
    return (
        <header className="headerMain">
            <img src={logo} alt="Mixes By Ben Logo" className="logo"/>
            <div className='buttons'>
                    <img src={hamburger} className="hamburger" alt="Dropdown menu"/>
                <div className="textButtons">
                    <a href="#what-i-do">WHAT I DO</a>
                    <a href="#how-it-works">HOW IT WORKS</a>
                    <a href="#contact">CONTACT</a>
                </div>
            </div>
        </header>
    )
}

export default Header