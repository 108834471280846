import './player.css'
import play from './play_circle.svg'
import pause from './pause_circle.svg'
import volumeMute from './volume_mute.svg'
import volumeLow from './volume_low.svg'
import volumeHigh from './volume_high.svg'
import {useState, useRef, useEffect} from 'react'

function Player({tracks}) {
    const [audioReference, setAudioReference] = useState([])
    const [playStatus, setPlayStatus] = useState(false)
    const [currentTrack, setCurrentTrack] = useState(-1)
    const [seeking, setSeeking] = useState(false)
    const [volume, setVolume] = useState(0.3)
    const seekLabel = useRef(null)
    const seekSlider = useRef(null)
    const volumeSlider = useRef(null)
    const volumeWrapper = useRef(null)

    useEffect(() => {
        if(/Mobi|Android/i.test(navigator.userAgent)) {
            volumeWrapper.current.style.display = 'none'
            volumeWrapper.current.parentElement.style.justifyContent = 'center'
        }
    }, [])

    function calcTime(seconds) {
        return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60).toString().padStart(2, '0')}`
    }

    function setSliderWidth(slider, val) {
        slider.style.backgroundSize = (val - slider.min) * 100 / (slider.max - slider.min) + '% 100%'
    }

    function metadataHandler(e) {
        e.target.volume = 0.3
        const duration = e.target.duration
        const filename = decodeURIComponent(e.target.src).split('/').pop().split('.')[0]
        const data = {
            reference: e.target,
            title: filename.split(' - ').slice(1).join('-'),
            artist: filename.split(' - ')[0],
            duration: calcTime(duration),
            resumeStatus: false,
        }
        setAudioReference(oldMetadata => [...oldMetadata, data])
    }

    function playHandler(e, index) {
        if(currentTrack === index && playStatus) {
            audioReference[index].reference.pause()
            setPlayStatus(false)
        } else {
            if(currentTrack !== index && playStatus) {
                audioReference[currentTrack].reference.pause()
                for(const ref in audioReference) {
                    audioReference[ref].reference.currentTime = 0
                }
            }
            audioReference[index].reference.play()
            setPlayStatus(true)
            setCurrentTrack(index)
        }
    }

    function endHandler(e) {
        if(currentTrack === audioReference.length - 1) {
            setPlayStatus(false)
        } else {
            audioReference[currentTrack + 1].reference.play()
            setPlayStatus(true)
            setCurrentTrack(currentTrack + 1)
        }
    }

    function volumeHandler(e) {
        setVolume(e.target.value / 100)
        setSliderWidth(volumeSlider.current, e.target.value)
        for(const ref in audioReference) {
            audioReference[ref].reference.volume = e.target.value / 100
        }
    }

    function seekLabelHandler(e) {
        setSeeking(true)
        seekLabel.current.innerHTML = calcTime(e.target.value)
        setSliderWidth(seekSlider.current, e.target.value)
    }

    function seekHandler(e) {
        setSeeking(false)
        audioReference[currentTrack].reference.currentTime = e.target.value
    }

    function timeUpdateHandler(e) {
        if(!seeking) {
            const time = Math.floor(e.target.currentTime)
            seekSlider.current.value = time
            seekLabel.current.innerHTML = calcTime(time)
            setSliderWidth(seekSlider.current, time)
        }
    }

    return (
        <div className="penis">
            <div className="topSection">
                <div>
                    Tracks mixed and/or mastered by Ben:
                </div>
                <div className="sliderWrapper">
                    <div className="volumeWrapper" ref={volumeWrapper}>
                        <img src={volume === 0 ? volumeMute : volume < 0.15 ? volumeLow : volumeHigh} alt=""/>
                        <input type="range" className="volume" name="volume" ref={volumeSlider} onInput={volumeHandler} min="0" max="30" defaultValue="30" style={{'backgroundSize': '100% 100%', 'width': '30%'}}/>
                    </div>
                    <div className="seekWrapper">
                        <label htmlFor="seek" ref={seekLabel}>0:00</label>
                        <input type="range" className="seek" name="seek" ref={seekSlider} onChange={seekLabelHandler} onMouseUp={seekHandler} onTouchEnd={seekHandler} min="0" max={audioReference[currentTrack] ? Math.floor(audioReference[currentTrack].reference.duration) : 0} />
                        <label htmlFor="seek">{audioReference[currentTrack] ? calcTime(audioReference[currentTrack].reference.duration) : "0:00"}</label>
                    </div>
                </div>
            </div>
            <div className="titleBar">
                <span id="number">#</span>
                <span id="title">TITLE</span>
                <span id="artist">ARTIST</span>
                <span id="duration">TIME</span>
            </div>
            <div className="tracks">
                {tracks.map((track, index) => (
                    <div className='track' key={track}>
                        <audio src={'http://mixesbyben.com/static/audio/' + track} onLoadedMetadata={metadataHandler} onEnded={endHandler} onTimeUpdate={timeUpdateHandler} preload="metadata"/>
                        <div className='play'>
                            <span className='number' style={currentTrack === index ? {display: 'none'} : {}}>{(index+1).toString().padStart(2, '0') + '.'}</span>
                            <button className='playButton' onClick={e => playHandler(e, index)}>
                                <img src={currentTrack === index && playStatus ? pause : play} alt={currentTrack === index && playStatus ? 'Pause button' : 'Play button'} style={currentTrack === index ? {display: 'inline'} : {}}/>
                            </button>
                        </div>
                        <span className='songTitle' title={audioReference[index] ? audioReference[index].title : null}>{audioReference[index] ? audioReference[index].title : null}</span>
                        <span className='artist' title={audioReference[index] ? audioReference[index].artist : null}>{audioReference[index] ? audioReference[index].artist : null}</span>
                        <span className='length'>{audioReference[index] ? audioReference[index].duration : null}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Player