import './footer.css'

function Footer() {
    return (
        <footer className="footerMain">
            <span>© MIXES BY BEN {(new Date().getFullYear())}</span>
            <div>
                <span>Designed by <a href="http://www.d30n.com/" target="_blank" rel="noreferrer">d30n.com</a></span>
                <span>Developed by <a href="https://scoresaber.com/u/76561198197234477" target="_blank" rel="noreferrer">Isaac Milstein</a></span>
            </div>
        </footer>
    )
}

export default Footer