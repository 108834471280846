import Header from './header.js'
import Footer from './footer.js'
import Player from './player.js'
import './App.css'
import {useState, useEffect, useRef} from 'react'

function App() {
    const [tracks, setTracks] = useState([])
    const [text, setText] = useState([])
    const firstHeightRef = useRef(null)

    const fetchTracks = async () => {
        const res = await fetch('http://mixesbyben.com/get_tracks.php')
        // console.log(await res.json())
        return res.json()
    }

    const fetchText = async () => {
        const first = await fetch('http://mixesbyben.com/static/text/first_paragraph.txt').then(res => res.text())
        const second = await fetch('http://mixesbyben.com/static/text/second_paragraph.txt').then(res => res.text())
        const divider = await fetch('http://mixesbyben.com/static/text/divider.txt').then(res => res.text())
        // const firstHeader = first.split('\n')[0]
        // const first = first.split('\n').slice(1).join('\n')
        // const secondHeader = second.split('\n')[0]
        // const secondBody = second.split('\n').slice(1).join('\n')
        return [first, divider, second]
    }

    useEffect(() => {
        fetchTracks().then(res => setTracks(res))
        fetchText().then(res => setText(res))
    }, [])

    return (
        <div className="main">
            <Header className="header"/>
                <div className="content">
                    <div className="first" id="what-i-do">
                        <span className="firstParagraph" ref={firstHeightRef} dangerouslySetInnerHTML={{"__html": text[0]}}>
                        </span>
                        <div className="player">
                            <Player tracks={tracks}/>
                        </div>
                    </div>
                    <div className="divider">
                        <span>{text[1]}</span>
                    </div>
                    <div className="second" id="how-it-works">
                        <span className="secondParagraph" dangerouslySetInnerHTML={{"__html": text[2]}}>
                        </span>
                    </div>
                    <form className="contact" method="post" action="submit_contact.php" id="contact">
                        <h3 style={{'margin':'0', 'color': 'white'}}><strong>CONTACT:</strong></h3>
                        <input type="text" placeholder="NAME" name="name"></input>
                        <input type="text" placeholder="EMAIL" name="email"></input>
                        <input type="text"  placeholder="SUBJECT" name="subject"></input>
                        <textarea placeholder="MESSAGE" name="message"></textarea>
                        <input type="submit" className="submitContact" value="Send"></input>
                    </form>
                </div>
            <Footer className="footer"/>
        </div>
    )
}

export default App